<template>
  <div>
    <network
      ref="network"
      class="network"
      :nodes="nodes"
      :edges="edges"
      :events="['click']"
      :options="options"
      @click="clickNode">
    </network>
  </div>
</template>

<script>
import '@/plugins/vue2vis.js'
import { useWorkspaceStore } from '@/stores/workspacestore'

export default {
  name: 'Workflow',
  data () {
    return {
      selectedElement: null,
      options: {
        layout: {
          hierarchical: {
            enabled: true,
            nodeSpacing: 300,
            levelSeparation: 100
          }

        },
        interaction: {
          dragNodes: true
        },
        physics: { enabled: false },
        nodes: { fixed: false }
      }
    }
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    return { workspaceStore }
  },
  computed: {
    nodes: function () {
      let allNodes = []
      this.workspaceStore.workflow['nodes'].forEach(node => {
        node['font'] = { multi: 'html' }
        node['margin'] = 10
        if (node.type === 'dataset') {
          if (node.user_name.length > 15) {
            node['label'] = '<b>' + node.user_name.substr(0, 15) + '...</b>'
          } else {
            node['label'] = '<b>' + node.user_name + '</b>'
          }
          node['shape'] = 'box'
          node['label'] += '\n<i>' + node['meta']['nature'] + '</i>'
          if (node['meta']['class'] === 'data.frame') {
            node['label'] += '\n<i>#rows: ' + node['meta']['nrow'] + ' #col.: ' + node['meta']['ncol'] + '</i>'
          }

          node['font']['align'] = 'left'
          node['color'] = {
            border: '#1574B7',
            background: '#ffffff',
            highlight: {
              border: '#1574B7',
              background: '#14bcef'
            },
            hover: {
              border: '#1574B7',
              background: '#14bcef'
            }
          }
          if (node.origin_dataset === 'none') {
            node['color']['background'] = ' #DFF4FC'
          }
          allNodes.push(node)
        } else if (node.type === 'analysis' || node.type === 'combined') {
          node['label'] = '<b>' + node.object_name + '</b>'
          node['color'] = {
            border: '#1574B7',
            background: '#ffffff',
            highlight: {
              border: '#1574B7',
              background: '#14bcef'
            },
            hover: {
              border: '#1574B7',
              background: '#14bcef'
            }
          }
          allNodes.push(node)
        }
      })
      return allNodes
    },
    edges: function () {
      let allEdges = []
      this.workspaceStore.workflow['edges'].forEach(edge => {
        allEdges.push({
          from: edge[0],
          to: edge[1],
          arrows: 'to'
        })
      })
      return allEdges
    }
  },
  methods: {
    redraw: function () {
      this.$refs.network.redraw()
      this.$refs.network.fit()
    },
    clickNode: function () {
      let selectedNodes = this.$refs.network.getSelectedNodes()
      this.selectedElement = null
      this.nodes.forEach(node => {
        if (selectedNodes.includes(node.id)) {
          this.selectedElement = node
        }
      })
      this.$emit('element-selected', this.selectedElement)
    }
  }
}
</script>
<style scoped>
.network {
  height: 300px;
  cursor: grab;
}
</style>
