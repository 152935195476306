<template>
  <div>
    <tiles>
      <card-widget
        class="tile is-child thin"
        :image="require('@/assets/icons/icone-datasets.svg')"
        :number="datasets.length"
        label="Datasets"
      />
      <card-widget
        class="tile is-child thin"
        :image="require('@/assets/icons/icone-analyses.svg')"
        :number="analyses.length"
        label="Analyses"
      />
      <card-component class="tile is-child thin">
        <div>
        <div class="level">
            <div class="is-widget-icon">
              <a @click.prevent="copyToClipboard()">
              <img class="img-link" :src="require('@/assets/icons/Bt-lien-inactif.svg')">
              </a>
            </div>
          <div class="is-widget-label" >
          <p style=" font-size: 16px;" >
            <a @click.prevent="copyToClipboard()" style="text-decoration: none; color:#7a7a7a;">
            Copy workspace url</a>
          </p></div>
          <div class="title"></div>
        </div>
        </div>
      </card-component>
    </tiles>
    <div class="columns">
      <div class="column ">
        <card-component title="Workflow" icon="hubzilla">
          <workflow
            ref="workflow"
            @element-selected="updateSelectedElement" />
        </card-component>
      </div>
    </div>
    <div class="columns" v-if="selectedElement">
      <div class="column">
        <dataset-view  v-if="getSelectedType==='dataset'"
            :dataset-name="selectedElement.object_name"
          />
        <analysis-view v-else-if="getSelectedType==='analysis'"
            :analysis="selectedElement"/>
        <div v-else>
          <!--Handle composed (nor analyse nor dataset)-->
          <combinedDF :object="selectedElement" />
        </div>
      </div>
    </div>
    <div class="columns" v-else>
        <div class="column">
          <datasets-table :checkable="true"/>
        </div>
        <div class="column">
            <analyses-table/>
        </div>
    </div>
  </div>
</template>

<script>
import Workflow from '@/components/Workflow.vue'
import { useWorkspaceStore } from '@/stores/workspacestore'
import apiService from '@/services/apiService'
export default {
  name: 'Workspace',
  components: {
    'datasets-table': () => import('@/components/DatasetsTable.vue'),
    'workflow': Workflow,
    'card-component': () => import('@/components/ui/CardComponent.vue'),
    'tiles': () => import('@/components/ui/Tiles.vue'),
    'card-widget': () => import('@/components/ui/CardWidget.vue'),
    'dataset-view': () => import('@/components/DatasetView'),
    'analyses-table': () => import('@/components/AnalysesTable.vue'),
    'analysis-view': () => import('@/components/AnalysisView'),
    'combinedDF': () => import('@/components/CombinedDFView')
  },
  data () {
    return {
      displayAddAnalysisModal: false,
      selectedElement: null,
      results: {}
    }
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    return { workspaceStore }
  },
  computed: {
    url: function () {
      let url = location.protocol + '//' + location.hostname
      if (location.port) {
        url += ':' + location.port
      }
      return url + '/workspace/' + this.workspaceStore.uuid
    },
    datasets: function () {
      return this.workspaceStore.datasets
    },
    analyses: function () {
      if (this.workspaceStore.analyses) {
        return this.workspaceStore.analyses
      } else {
        return []
      }
    },
    getSelectedType: function () {
      let is = null
      if (this.selectedElement) {
        if (this.selectedElement.type === 'analysis') {
          is = 'analysis'
        } else if (this.selectedElement.meta.class === 'data.frame') {
          is = 'dataset'
        } else { // combined object
          is = this.selectedElement.meta.nature
        }
      }
      return is
    }
  },
  mounted () {
    const loadingComponent = this.$buefy.loading.open({
      container: null
    })
    // first set uuid from route
    let uuid = this.$route.params.uuid
    if (uuid !== undefined) {
      apiService
        .UUIDExists({ uuid: uuid })
        .then((data) => {
          if (!data.exists) {
            this.$router.push({ name: '404' })
            loadingComponent.close()
          } else {
            this.workspaceStore.loadWorkspace(uuid)
            this.refreshGraph()
            loadingComponent.close()
          }
        })
        .catch((e) => {
          this.$router.push({ name: '404' })
          loadingComponent.close()
        })
    } else {
      this.refreshGraph()
      loadingComponent.close()
    }
  },
  methods: {
    refreshGraph: function () {
      this.workspaceStore.getWorkflow()
        .then(() => {
          this.$refs.workflow.redraw()
        })
        .catch(() => {
          this.$refs.workflow.redraw()
        })
    },
    addAnalysis: function () {
      this.displayAddAnalysisModal = true
    },
    updateSelectedElement: function (element) {
      this.selectedElement = element
    },
    displayResults: function (results) {
      this.results = results
      this.displayAddAnalysisModal = false
      this.$refs.workflow.redraw()
    },
    copyToClipboard: function () {
      // navigator clipboard api needs a secure context (https)
      if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method'
        navigator.clipboard.writeText(this.url).then(() => {
          this.$buefy.toast.open({
            message: 'Copy to clipboard',
            queue: false
          })
        })
          .catch(() => {
            this.$buefy.toast.open({
              message: 'Unable to copy to clipboard',
              queue: false
            })
          })
      } else {
        // text area method
        let textArea = document.createElement('textarea')
        textArea.value = this.url
        // make the textarea out of viewport
        textArea.style.position = 'fixed'
        textArea.style.left = '-999999px'
        textArea.style.top = '-999999px'
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()
        try {
          var successful = document.execCommand('copy')
          var msg = successful ? 'successful' : 'unsuccessful'
          this.$buefy.toast.open({
            message: 'Copy to clipboard : ' + msg,
            queue: false
          })
        } catch (err) {
          this.$buefy.toast.open({
            message: 'Unable to copy to clipboard',
            queue: false
          })
        }
        document.body.removeChild(textArea)
      }
    }
  }
}
</script>
<style>
.img-link {
  margin-top:2px;
  width: 40px;
}
.img-link:hover {
  content: url("../assets/icons/Bt-lien-actif.svg");
}
div .thin >.card-content {
  padding: 10px 2px 2px 2px !important;
}
.tile .is-parent {
  padding-bottom: 5px;
  padding-top: 5px;

}
</style>
